import React, {Component} from 'react';
import {Redirect, Link as RouterLink} from 'react-router-dom';
import PropTypes from 'prop-types';
import {connect} from 'react-redux'
import {loginUser} from "../../actions/auth";
import withStyles from "@material-ui/core/styles/withStyles";
import {TextField, Button, Typography, Container, Avatar, Link} from "@material-ui/core";
import {LockOutlined} from '@material-ui/icons';
import {withTranslation} from 'react-i18next';

const useStyles = theme => {
    return ({
        paper: {
            marginTop: theme.spacing(8),
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
        },
        avatar: {
            margin: theme.spacing(1),
            backgroundColor: theme.palette.secondary.main,
        },
        form: {
            width: '100%',
            marginTop: theme.spacing(1),
        },
        submit: {
            margin: theme.spacing(3, 0, 2),
        },
    })
};

const RequestResetPassword = React.forwardRef((props, ref) => (
  <RouterLink ref={ref} to="/request_reset_password" {...props} />
));

class Login extends Component {
    static propTypes = {
        loginUser: PropTypes.func.isRequired,
        isAuthenticated: PropTypes.bool
    };

    state = {
        username: '',
        password: ''
    };

    onChange = event => {
        const value = event.target.type === "checkbox" ? event.target.checked : event.target.value;
        this.setState({[event.target.name]: value});
    };

    onSubmit = event => {
        event.preventDefault();
        const {username, password} = this.state;
        const credentials = {username, password};
        this.props.loginUser(credentials);
        this.setState({
            username: '',
            password: ''
        });
    };

    render() {
        if (this.props.isAuthenticated){
            return <Redirect to="/"/>
        }
        const {username, password} = this.state;
        const {classes, t} = this.props;
        return (
            <Container maxWidth="xs">
                <div className={classes.paper}>
                    <Avatar className={classes.avatar}>
                        <LockOutlined/>
                    </Avatar>
                    <Typography component="h1" variant="h5">
                        {t('signIn')}
                    </Typography>
                    <form className={classes.form} noValidate onSubmit={this.onSubmit}>
                        <TextField
                            onChange={this.onChange}
                            variant="outlined"
                            margin="normal"
                            required
                            fullWidth
                            id="username"
                            label={t('userName')}
                            name="username"
                            value={username}
                            autoFocus
                            autoComplete="username"
                        />
                        <TextField
                            onChange={this.onChange}
                            variant="outlined"
                            margin="normal"
                            required
                            fullWidth
                            value={password}
                            name="password"
                            label={t('passWord')}
                            type="password"
                            id="password"
                            autoComplete="current-password"
                        />
                        <Link component={RequestResetPassword}>{t('forgotPassword')}</Link>
                        <Button
                            type="submit"
                            fullWidth
                            variant="contained"
                            color="primary"
                            className={classes.submit}
                        >
                            {t('signIn')}
                        </Button>
                    </form>
                </div>
            </Container>

        );
    }
}

const mapStateToProps = state => ({
    isAuthenticated: state.authReducer.isAuthenticated
});

export default withStyles(useStyles)(connect(mapStateToProps, {loginUser})(withTranslation('login')(Login)));